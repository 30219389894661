var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"photo-approve flex-column flex flex-1 oh"},[_c('div',{staticClass:"flex-not-shrink",staticStyle:{"padding":"20px 20px 0"}},[(_vm.visibleSearch)?_c('view-search-form',{ref:"searchFormRef",attrs:{"keyword-placeholder":"学生姓名","query-info":_vm.tableQuery,"rules":_vm.rules,"tool-list":['keyword']},on:{"update:queryInfo":function($event){_vm.tableQuery=$event},"update:query-info":function($event){_vm.tableQuery=$event},"on-search":_vm.renderTable}},[_c('el-form-item',{attrs:{"label":"管辖楼栋","prop":"dormitoryId"}},[_c('tool-tips-group',{attrs:{"is-flex":"","tips-content":"1.去系统设置-用户管理处把账号的\"部门\"改为公寓服务中心;\n2. 到 公寓服务中心-楼况设置 模块，把宿舍楼中的宿舍管理员分配给本账号；"}},[_c('el-select',{attrs:{"size":"small","no-match-text":"暂未分配楼栋"},on:{"change":_vm.createFloor},model:{value:(_vm.tableQuery.dormitoryId),callback:function ($$v) {_vm.$set(_vm.tableQuery, "dormitoryId", $$v)},expression:"tableQuery.dormitoryId"}},_vm._l((_vm.dormBuildList),function(ref){
var dormitoryName = ref.dormitoryName;
var id = ref.id;
return _c('el-option',{key:id,attrs:{"label":dormitoryName,"value":id}})}),1)],1)],1),_c('el-form-item',{attrs:{"label":"楼层","prop":"floorNum"}},[_c('el-select',{attrs:{"clearable":"","filterable":"","size":"small"},model:{value:(_vm.tableQuery.floorNum),callback:function ($$v) {_vm.$set(_vm.tableQuery, "floorNum", $$v)},expression:"tableQuery.floorNum"}},_vm._l((_vm.floorList),function(ref){
var label = ref.label;
var value = ref.value;
return _c('el-option',{key:value,attrs:{"label":label,"value":value}})}),1)],1),_c('el-form-item',{attrs:{"label":"审核状态","prop":"headshotStatus"}},[_c('el-select',{attrs:{"clearable":"","filterable":"","size":"small"},model:{value:(_vm.tableQuery.headshotStatus),callback:function ($$v) {_vm.$set(_vm.tableQuery, "headshotStatus", $$v)},expression:"tableQuery.headshotStatus"}},_vm._l((_vm.$store.state.systemParam.reviewStatus),function(ref){
var paramValue = ref.paramValue;
var label = ref.label;
return _c('el-option',{key:paramValue,attrs:{"label":label,"value":paramValue}})}),1)],1)],1):_vm._e(),_c('div',{staticClass:"mb-1"},[_c('p',{staticClass:"font-grey mt-1",staticStyle:{"display":"inline-block","font-size":"13px"}},[_vm._v("搜索结果: "+_vm._s(_vm.tableData.length)+" 人, "),_c('span',[_vm._v("已选中 "),_c('b',{staticStyle:{"color":"var(--color-primary)"}},[_vm._v(_vm._s(_vm.rowCheckList.length))]),_vm._v(" 人")])]),_c('div',{staticClass:"header-button fr"},[_c('el-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-refresh"},on:{"click":_vm.updateUserPert}},[_vm._v(" 同步学生信息到闸机系统 ")])],1)])],1),_c('div',{staticClass:"flex-column photo-approve__body flex flex-1 oh"},[_c('el-table',{staticClass:"select-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"empty-text":"请先选择楼栋、楼层","height":"100%","border":"","stripe":""},on:{"selection-change":_vm.handleSelectChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"50","align":"center","selectable":_vm.handleRowSelectable}}),_c('el-table-column',{attrs:{"prop":"stuNo","label":"学号","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.stuNo ? row.stuNo : '未注册')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"stuName","label":"姓名","width":"120"}}),_c('el-table-column',{attrs:{"prop":"apartmentName","label":"宿舍","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.dormitoryName && row.dormitoryBedNo)?_c('span',[_vm._v(_vm._s(row.dormitoryName)+_vm._s(row.roomName)+"室"+_vm._s(row.dormitoryBedNo)+"号床")]):(row.stuStatus===2)?_c('span',{staticClass:"font-grey"},[_vm._v("已毕业")]):_c('span',[_vm._v("未分配")])]}}])}),_c('el-table-column',{attrs:{"label":"高考照片","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.stuExamImg)?_c('el-image',{attrs:{"src":("" + _vm.$fileUrl + (row.stuExamImg)),"preview-src-list":[("" + _vm.$fileUrl + (row.stuExamImg))],"fit":"contain"}}):_c('span',{staticClass:"font-grey"},[_vm._v("待上传")])]}}])}),_c('el-table-column',{attrs:{"label":"门禁照片","width":"200","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.headshot)?_c('el-image',{attrs:{"src":("" + _vm.$fileUrl + (row.headshot)),"preview-src-list":[("" + _vm.$fileUrl + (row.headshot))],"fit":"contain"}}):_c('span',{staticClass:"font-grey"},[_vm._v("待上传")])]}}])}),_c('el-table-column',{attrs:{"label":"驳回理由","prop":"headshotMsg","min-width":"250"}}),_c('el-table-column',{attrs:{"label":"照片审核","width":"140","align":"center","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.headshotStatus===1)?[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['photo:review']),expression:"['photo:review']"}],attrs:{"size":"mini","type":"success"},on:{"click":function($event){return _vm.handleApprove(3, row)}}},[_vm._v(" 通过 ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(['photo:review']),expression:"['photo:review']"}],attrs:{"size":"mini","type":"warning"},on:{"click":function($event){return _vm.handleApprove(2, row)}}},[_vm._v(" 驳回 ")])]:_vm._e(),_c('span',{staticClass:"font-grey"},[_vm._v(_vm._s(row.headshotStatus === 3 ? '已通过' : (row.headshotStatus === 2 ? '已驳回' : '')))])]}}])})],1)],1),_c('div',{directives:[{name:"permission",rawName:"v-permission",value:(['photo:review']),expression:"['photo:review']"}],staticClass:"photo-approve__footer flex-not-shrink"},[_c('el-button',{attrs:{"type":"success","disabled":!_vm.rowCheckList.length},on:{"click":function($event){return _vm.handleBatchApprove(3)}}},[_vm._v("一键通过 ")]),_c('el-button',{attrs:{"type":"warning","disabled":!_vm.rowCheckList.length},on:{"click":function($event){return _vm.handleBatchApprove(2)}}},[_vm._v("一键驳回 ")])],1),(_vm.visibleDialog)?_c('photo-approve-dialog',{ref:"dialogRef",attrs:{"visible":_vm.visibleDialog},on:{"update:visible":function($event){_vm.visibleDialog=$event},"on-close":_vm.renderTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }